<template>
  <CreateTenderDialog
    v-if="store.myTendersFetched"
    :tender
    :tender-stub="tenderStub"
    :create-button-disabled="!isDraftValid()"
    :createAsTemplate
  >
    <template #create-tender-wizard-step>
      <CreateTenderWizardStep :createAsTemplate></CreateTenderWizardStep>
    </template>
  </CreateTenderDialog>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { Tender, useStore } from '@dims/components';
import CreateTenderWizardStep from '@/components/create-tender/CreateTenderWizardStep.vue';
import { useStore0207 } from '@/store/store0207';
import { tenderStub as defaultTenderStub } from '@/services';

const { tender = null, createAsTemplate = false } = defineProps<{
  /** Only when the create/clone button is on an existing tender */
  tender?: Tender | null,
  createAsTemplate?: boolean }>();
const store = useStore();
const store0207 = useStore0207();

function isDraftValid() {
  return store0207.draftTender?.description
    && store0207.draftTender.awardCriteriaType;
}

const tenderStub = computed(() => ({ ...defaultTenderStub, agreementName: store.agreementId }));

</script>
