import { Offer } from '@dims/components';

const offerService = {
  offerHasQuestionnaire: true,
  areOfferDetailsValid: (_offer: Offer): boolean => true,
  allNonValidDetails: (_offer: Offer): string[] => {
    const result: string[] = [];
    return result;
  },
};

export default offerService;
