<template>
  <BpqDocumentationDialogBase
    :offer
    :index
    :isOfferConditional="conditionalOffers.includes(offer)"
    :criterias="createCriterias()"
    :offerScore="offer.data.bpqScore"
    :qualityPercentage="bpqRatioPercent"
    :firstColumnWidth="6"
    :pricePoints="{
      minPoints: 1,
      maxPoints: 10,
      minPrice: lowestPrice,
      tcoPrice: offer.data.tcoCalculationResult?.tcoPrice ?? 0,
      alpha,
      beta,
      priceScore: offer.data.bpqPriceScore ?? 0,
    }"
  />
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { OfferEvaluation, WeightedScoreViewModel, BpqDocumentationDialogBase } from '@dims/components';
import { qualityRatioPercentage, getEvaluationSpan } from '@/services/utils0207';
import { Tender0207 } from '@/models/Tender';
import { Offer0207 } from '@/models/Offer';
import bpqCriteria from '@/services/bpqCriteria';

const { tender, offer, offerEvaluation, conditionalOffers } = defineProps<{
  tender: Tender0207,
  offer: Offer0207,
  offerEvaluation?: OfferEvaluation,
  conditionalOffers: Offer0207[],
  index: number,
}>();

function createCriterias() {
  if (offer.isEvaluated && offerEvaluation) {
    return bpqCriteria.getCategories(tender.data, offerEvaluation)
      .map((c) => new WeightedScoreViewModel(c.key, c.label, c.score, c.weight));
  }

  return bpqCriteria.getEmptyCategories(tender.data)
    .map((c) => new WeightedScoreViewModel(c.key, c.label, c.score, c.weight));
}

const lowestPrice = computed(() => {
  const prices = conditionalOffers.map((o) => o.data.tcoCalculationResult?.tcoPrice ?? 0);
  return Math.min(...prices);
});

const bpqRatioPercent = computed(() => qualityRatioPercentage(tender));

const alpha = computed(() => {
  const prices = conditionalOffers.map((o) => o.data.tcoCalculationResult?.tcoPrice ?? 0);
  const evaluationSpan = (tender.data.bpqPriceEvaluationSpanPercentage ?? 0) / 100;
  const extendedPriceSpan = getEvaluationSpan(
    tender.data,
    prices,
    evaluationSpan,
    lowestPrice.value,
  );
  return (
    (1 - 10) / (lowestPrice.value * (1 + extendedPriceSpan) - lowestPrice.value)
  );
});

const beta = computed(() => 10 - alpha.value * lowestPrice.value);
</script>
