import { DraftTender, useStore } from '@dims/components';
import { defineStore } from 'pinia';

export const useStore0207 = defineStore('0207', {
  getters: {
    draftTender() {
      const store = useStore();
      return store.draftTender as DraftTender | null;
    },
  },
  actions: {
    setDraftTender(tender: DraftTender) {
      const store = useStore();
      store.setDraftTender(tender);
    },
  },
});

export default useStore0207;
