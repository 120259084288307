<!--
  DO NOT EDIT THIS FILE

  File is duplicated across sites, so should be kept in sync
-->
<template>
  <div>
    <h2 class="mt-0 mb-2">Upload ESPD</h2>
    <div v-if="espdFile">
      <v-row>
        <v-col cols="10">
          <DownloadArtifact
            :tender
            :offer
            templateKey="offer"
            templateArtifactKey="espd"
          >
            ESPD-fil
          </DownloadArtifact> <v-icon color="green">mdi-check</v-icon>
        </v-col>
        <v-col v-if="offer.status === 'Draft'" class="d-flex justify-end">
          <v-btn @click="deleteFile">Slet</v-btn>
        </v-col>
      </v-row>
    </div>
    <div v-else>
      <p>Du kan her uploade det ESPD, som ligger til grund for din optagelse på aftalen.</p>
      <v-btn
        @click="addFile()"
        class="primary-button-with-arrow"
        :disabled
      >
        <span>Upload ESPD-dokument</span>
        <v-icon size="small">mdi-upload</v-icon>
      </v-btn>
      <v-file-input
        style="display: none"
        hidden
        ref="fileInput"
        @change="onFilePicked"
        v-model="file"
      ></v-file-input>
      <LoadingSpinner loading-message="Uploader fil" :visible="isLoading" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, useTemplateRef } from 'vue';
import {
  LoadingSpinner,
  Offer,
  Tender,
  useStore,
  DownloadArtifact,
  OfferArtifact,
} from '@dims/components';

const emit = defineEmits<{ fileUploaded: [] }>();
const { tender, offer, disabled = false, espdFile = null } = defineProps<{
    tender: Tender,
    offer: Offer,
    disabled?: boolean,
    espdFile?: OfferArtifact | null }>();
const store = useStore();
const isLoading = ref(false);
const file = ref<File>();

const fileInput = useTemplateRef<HTMLInputElement>('fileInput');

function addFile() {
  if (fileInput.value) {
    fileInput.value.click();
  }
}

async function onFilePicked() {
  if (!file.value) return;
  isLoading.value = true;
  await uploadFile('offer', 'espd', file.value);
  emit('fileUploaded');
  file.value = undefined;
  isLoading.value = false;
}

async function uploadFile(
  templateKey: string,
  templateArtifactKey: string,
  newFile: File,
) {
  try {
    await store.uploadOfferArtifactAction({
      offer,
      templateKey,
      templateArtifactKey,
      file: newFile,
    });
  } catch {
    unableToUpload();
  }
}

function unableToUpload() {
  store.setSnackbarText('Kan ikke uploade dokument.');
}

async function deleteFile() {
  await store.deleteOfferArtifactAction(
    {
      offer,
      templateKey: 'offer',
      templateArtifactKey: 'espd',
    },
  );
}

</script>
