import { AwardCriteriaType } from '@dims/components';

const awardCriteriaType = {
  getLabel: (type: AwardCriteriaType): string => {
    switch (type) {
    // Best price-quality ratio
      case 'BPQRatio':
        return 'Kategori 2 - Kompleks løsning';
      case 'TCO':
        return 'Kategori 1 - Simpel løsning';
      default:
        return 'Unknown award type';
    }
  },
};

export default awardCriteriaType;
