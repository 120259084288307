<template>
  <v-card flat>
    <v-card-title>
      <h2 class="header text-uppercase text-primary">Behovsafdækning</h2>
    </v-card-title>
    <v-card-text>
      <p>Du skal i behovsafdækningen tage en række overordnede valg, der skal hjælpe med at målrette materialet til den efterfølgende kravspecifikation.
      </p>
      <p>Du skal igennem følgende trin:</p>
      <ol class="mb-4">
        <li>Først skal du tage stilling til, hvilke af de overordnede kategorier du vil købe ind under:
          <ul>
            <li>Netværksprodukter og -løsninger</li>
            <li>Overvågningsprodukter og -løsninger</li>
            <li>IT-sikkerhedsprodukter og -løsninger</li>
            <li>Unified Communications-produkter og -løsninger</li>
            <li>Kontaktcenterløsning</li>
            <li>Internet of things</li>
          </ul>
        </li>
        <li class="mt-4">
          Dernæst skal du tage stilling til, hvilke tværgående ydelser der skal indgå i dit indkøb.
        </li>
      </ol>
      <p>Når du har udfyldt alle trin i behovsafdækningen, får du en samlet rapport, du kan downloade og dele i din organisation. I rapporten kan du se, hvilke bilag der er relevante for dit indkøb, og hvilke tilpasninger SKI anbefaler.</p>
      <p>Hvis du har spørgsmål eller brug for rådgivning til behovsafdækningen, er du altid velkommen til at kontakte os.</p>
      <IconExplanation />
    </v-card-text>
  </v-card>
</template>

<script setup lang="ts">

</script>

<style scoped>
.pre-formatted {
  white-space: pre-wrap !important;
}

.header {
  font-size: 30px;
  margin-top: 0;
}
</style>
