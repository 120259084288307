import { AgreementSpecific } from '@dims/components';
import FilterConfiguration from '@/services/FilterConfiguration';
import bpqCriteria from './services/bpqCriteria';

const agreementSpecific: AgreementSpecific = {
  createFilter() { return new FilterConfiguration(); },
  bpqCriteria,
};

export default agreementSpecific;
