<template>
  <h2 class="mt-0">Beregningsmetode</h2>
  <br />
  <p>
    Der foretages udregning af kontraktsum
    <CalculationTag
      :isHovered="hoveredTag === 'A'"
      isInteractive
      @highlightTag="highlightTag('A')"
      @unhighlightTag="unhighlightTag"
    >A</CalculationTag>
    og TCO (hvis kunden har valgt det i kravspecificationen)
    <CalculationTag
      :isHovered="hoveredTag === 'T'"
      isInteractive
      @highlightTag="highlightTag('T')"
      @unhighlightTag="unhighlightTag"
    >T</CalculationTag>.
    <br />
    De to værdier summeres til en samlet evalueringsteknisk pris
    <CalculationTag
      :isHovered="hoveredTag === 'B'"
      isInteractive
      @highlightTag="highlightTag('B')"
      @unhighlightTag="unhighlightTag"
    >B</CalculationTag>.
    <br />
    <br />
    Kontraktsum <CalculationTag
      :isHovered="hoveredTag === 'A'"
      isInteractive
      @highlightTag="highlightTag('A')"
      @unhighlightTag="unhighlightTag"
    >A</CalculationTag> beregnes som summen af leverandørens pris for opsætning, licens og produkter.
    Tilbudt pris for opsætning angives som en samlet pris per område.
    <br />
    Tilbudt pris for licens og produkter angives per enhed og skal derfor ganges med det samlede antal (inkl. optioner),
    som kunden ønsker.
    <br />
    <br />
    TCO (Total Cost of Ownership) <CalculationTag
      :isHovered="hoveredTag === 'T'"
      isInteractive
      @highlightTag="highlightTag('T')"
      @unhighlightTag="unhighlightTag"
    >T</CalculationTag> er den forventede pris for strømforbuget for dette indkøb i TCO perioden.
    Det beregnes som summen af TCO for hvert bestilt produkt.
    <br />
    Kunden angiver elprisen (kr/kWh) <CalculationTag
      :isHovered="hoveredTag === 'E'"
      isInteractive
      @highlightTag="highlightTag('E')"
      @unhighlightTag="unhighlightTag"
    >E</CalculationTag> samt forventet andel (%) af perioden i tilstandene "Drift", "Stand-by" og "Slukket".
    Leverandøren angiver produktets strømforbrug (W) i hver tilstand.
    <br />
    Der er 3 trin i beregningen:
    <br />
    1: Det gennemsnitlige strømforbrug (W) beregnes
    som en vægtet sum over de tre tilstande.
    <br />
    2: Strømforbruget for hvert produkt ganges herefter op med antal enheder, elprisen
    og TCO periodens længde for at give TCO for hvert produkt.
    <br />
    3: Til slut summeres alle bestilte produkters TCO værdier for at give totalen
    <CalculationTag
      :isHovered="hoveredTag === 'T'"
      isInteractive
      @highlightTag="highlightTag('T')"
      @unhighlightTag="unhighlightTag"
    >T</CalculationTag>.
  </p>
</template>

<script setup lang="ts">
import { CalculationTag } from '@dims/components';
import { ref } from 'vue';

const emit = defineEmits<{
  updateHoveredTag: [string]
  }>();
const hoveredTag = ref<string>();

function highlightTag(tag: string) {
  hoveredTag.value = tag;
  emit('updateHoveredTag', tag);
}
function unhighlightTag() {
  hoveredTag.value = '';
  emit('updateHoveredTag', '');
}

</script>
