<template>
  <TenderTerminationDialogBase
    :tender
    :disabled
    :conditionalOffers
    :unconditionalOffers
    :agreementSpecific="agreementSpecificHandler"
    @updateTab="emit('updateTab', $event)"
  >
    <template #page1-tender>
      <v-row>
        <v-col cols="4">
          <strong>Tildelingskriterium:</strong>
          {{ awardTypeLabels(awardCriteriaType) }}
        </v-col>
      </v-row>
    </template>
    <template #page1-winning-offer>
    </template>
  </TenderTerminationDialogBase>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import {
  Offer,
  TenderTerminationDialogBase,
} from '@dims/components';
import { Tender0207 } from '@/models/Tender';
import { awardCriteriaType as awardCriteriaTypeLabels } from '@/services';

const emit = defineEmits<{
  updateTab: [string]
  }>();

const { tender, disabled = false, conditionalOffers, unconditionalOffers } = defineProps<{
  tender: Tender0207,
  disabled?: boolean,
  conditionalOffers: Offer[],
  unconditionalOffers: Offer[] }>();
const awardTypeLabels = awardCriteriaTypeLabels.getLabel;
// No customization
const agreementSpecificHandler = {
  isTenderChanged: () => false,
  validTenderAndOfferData: () => true,
  updateTenderData: () => ({}),
};

const awardCriteriaType = computed(() => tender.awardCriteriaType);

</script>
