<template>
  <v-card flat>
    <v-card-title>
      <h2 class="header text-uppercase text-primary">
        Behovsafdækningsrapport
      </h2>
    </v-card-title>
    <v-card-text>
      <PrintableRegion
        fileName="Behovsafdækningsrapport.pdf"
        header="Behovsafdækningsrapport"
        css="
.mb-1 { margin-bottom: 4px; }
.pl-4 { padding-left: 4px; }
/* wkhtmltopdf does not support flex, but uses -webkit-box */
.d-flex {
  display: -webkit-box; /* wkhtmltopdf uses this one */
}
.box {
  width: 230px;
  height: 80px;
}
.divider {
  height: 80px;
  width: 1px;
  border-left: 2px dotted grey;
}
i.mdi-checkbox-marked,
i.mdi-checkbox-blank-outline {
  font-style: normal;
  padding-right: 1em;
}
i.mdi-checkbox-marked::before {
  content: '☑'
}
i.mdi-checkbox-blank-outline::before {
  content: '☐'
}
      "
      >
        <p>
          Dette er din formelle rapport om, hvad du har behov for i forbindelse
          med dit indkøb.
        </p>
        <!-- Behovsafdækning  -->
        <template v-if="questions[0]">
          <h2 class="text-uppercase">Behovsafdækning</h2>
          <br />
          <ReportBoxDiagram :reportModel />
        </template>
        <div>
          <!-- Tværgående ydelser -->
          <div>
            <h2 class="text-uppercase">Tværgående afklaringer</h2>
            <br />
            <div v-for="question in crossSectionalQuestions" :key="question.questionId">
              <div
                v-for="subquestion in question.questions || []"
                :key="subquestion.questionId"
              >
                <v-row class="align-center">
                  <v-col>
                    <div>
                      <v-checkbox-btn
                        disabled
                        :model-value="questionValue(subquestion)"
                        :label="question.title"
                      />
                    </div>
                  </v-col>
                </v-row>
                <v-divider></v-divider>
              </div>
            </div>
          </div>
        </div>
      </PrintableRegion>
    </v-card-text>
  </v-card>
</template>

<script setup lang="ts">
import {
  DeepQuestion,
  PrintableRegion,
  ReportBoxDiagram,
} from '@dims/components';
import { computed } from 'vue';

const { questions } = defineProps<{ questions: DeepQuestion[] }>();

const crossSectionalQuestions = computed(() => questions[2]?.questions ?? []);

const reportModel = computed(() => (questions[0]?.questions ?? []).map((q) => ({
  title: q.title ?? '(Titel mangler)',
  selected: questionSelected(q),
  children: clarificationSubQuestions(q).map((subQuestion) => ({
    title: subQuestion.title ?? '(Titel mangler)',
    selected: questionSelected(q) && questionSelected(subQuestion),
  })),
})));

function questionSelected(question: DeepQuestion) {
  if (question.questionType === 'Section') {
    const child = firstChildQuestion(question) ?? undefined;
    return child && questionValue(child);
  }
  return questionValue(question);
}

function questionValue(question: DeepQuestion) {
  if (question.questionType === 'Options') {
    const yesOption = question.options.find((option) => option.text === 'Ja');
    return yesOption && question.value === yesOption.optionId;
  }
  if (question.questionType === 'Boolean') {
    return question.value === 'true';
  }
  return false;
}

function clarificationSubQuestions(question: DeepQuestion) {
  return firstChildQuestion(question)?.questions ?? [];
}

function firstChildQuestion(question: DeepQuestion) {
  return question.questions?.[0];
}

</script>

<style scoped>
.header {
  font-size: 30px;
}

</style>
