<template>
  <v-radio-group
    v-model="selectedAwardType"
    :hide-details="true"
    density="comfortable"
  >
    <div class="d-flex">
      <v-radio
        color="accent"
        :value="'TCO'"
      >
        <template #label>
          {{ awardTypeLabel('TCO') }}
          <DimsTooltip>
            <div class="tooltip">
              Anvendes, hvis indkøbet er et præcist specificeret standardindkøb eller volumenkøb, hvor der evalueres på pris/omkostninger.
            </div>
          </DimsTooltip>
        </template>
      </v-radio>
    </div>
    <div class="d-flex">
      <v-radio
        color="accent"
        :value="'BPQRatio'"
      >
        <template #label>
          {{ awardTypeLabel('BPQRatio') }}
          <DimsTooltip>
            <div class="tooltip">
              Anvendes, hvis indkøbet er et specialiseret og komplekst løsningskøb, hvor der evalueres på kvaliteten af forskellige løsninger.
            </div>
          </DimsTooltip>
        </template>
      </v-radio>
    </div>
  </v-radio-group>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { AwardCriteriaType } from '@dims/components';
import { useStore0207 } from '@/store/store0207';
import { awardCriteriaType } from '@/services';

const store = useStore0207();

const selectedAwardType = computed({
  get(): AwardCriteriaType | undefined {
    let type;
    if (store.draftTender) {
      type = store.draftTender.awardCriteriaType;
    }

    return type;
  },

  set(type: AwardCriteriaType | undefined) {
    if (store.draftTender && type) {
      store.setDraftTender({
        ...store.draftTender,
        awardCriteriaType: type,
        data: { ...store.draftTender.data },
      });
    }
  },
});

function awardTypeLabel(type: AwardCriteriaType): string {
  return awardCriteriaType.getLabel(type);
}

</script>
<style scoped>
.tooltip {
  max-width: 30vw;
}
</style>
